import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useLocation, useParams } from "react-router-dom";
import TagList from "../Components/TagList";
import '../CSS/profile.css';
import useAuth from "../hooks/useAuth";
import PostList from "../Components/PostList";
import useNotifs from "../hooks/useNotifs";

const User = () => {
    const { auth, setAuth } = useAuth();
    const { wsRef, refreshChats, notifs } = useNotifs();
    const [ profile, setProfile ] = useState();
    const [ reported, setReported ] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const username = params.username?.toString() || null;
    const location = useLocation();
    const from = location.state?.from?.pathname;

    useEffect(() => {
        if(!profile || profile.username !== username)
        {
            const getProfile = async () => {
                try {
                    const response = await axiosPrivate.get(`/user/${username}`);
                    setProfile(response.data);
                } catch (err) {
                    console.error(err);
                }
            };
            getProfile();
        }
    }, [axiosPrivate, username, profile ]);

    const addFriend = async () => {
        try {
            if(!profile.friends.includes(auth.userId) ) {
                const isIncomingReq = notifs.find(notif => notif.text === (profile.username + " sent you a friend request"));
                if(isIncomingReq) {
                    await axiosPrivate.put(`/user/${profile._id}`, 
                        {reqUserId:auth.userId}, 
                        {
                            headers: { 'Content-Type': 'application/json'},
                            withCredentials: true
                        }
                    );
                    await wsRef.send(JSON.stringify({
                        friendUpdate: "added",
                        receiver: profile._id,
                    }));
                    await axiosPrivate.delete(`/notifications/${isIncomingReq._id}`);
                    notifs.splice(notifs.indexOf(isIncomingReq), 1);
                    const newProfile = profile;
                    newProfile.friends.push(auth.userId);
                    setProfile(newProfile);
                    await refreshChats();
                } else {
                    const createdNotif = await axiosPrivate.post(`/notifications`, 
                        { notifType: "friend",  notifOwner: profile._id, notifSender: auth.username, reference: auth.userId}, 
                        {
                            headers: { 'Content-Type': 'application/json'},
                            withCredentials: true
                        }
                    );
                    await wsRef.send(JSON.stringify({
                        notif: createdNotif.data,
                        receiver: profile._id,
                    }));
                    const newProfile = profile;
                    newProfile.friendReqs.push(auth.username);
                    setProfile(newProfile);
                    await refreshChats();
                }
            } else {
                    await axiosPrivate.put(`/user/${profile._id}`, 
                    {reqUserId:auth.userId}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                    );
                    await wsRef.send(JSON.stringify({
                        friendUpdate: "removed",
                        receiver: profile._id,
                    }));
                    const newProfile = profile;
                    newProfile.friends.splice(profile.friends.indexOf(auth.userId));
                    setProfile(newProfile);
                    await refreshChats();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const reportUser = async () => {
        try {
            await axiosPrivate.post(`/report/${profile._id}`, 
                {userId: auth.userId}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setReported(true);
        } catch (err) {
            console.error(err);
        }
    };

    const handleLogOut = async () => {
        try {
            await axiosPrivate.post('/logout');
            setAuth({});
            window.location.reload(false); 
        } catch (err) {
            console.error(err);
        }
    };

    const formatText = (text) => {
        const lines = text.split('\n');
        return(
            <>
                {lines.map((line, index) => (
                    <p className="m-0" key={index}>{line}</p>
                ))}
            </>
        );
    };

    return(
        <>
            <div style={{marginTop: "56px"}}>
                {from
                    ? 
                    <Link className="back-button" to={from} title="Back">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                        </svg>
                    </Link>
                    : <></>
                }
                {profile
                    ? (
                        <>
                            <div className="non-sticky-cover mx-auto pb-0">
                                <section className="profile-card">
                                    <header className="profile-header">
                                        <h2 className="">
                                            {profile.username}
                                        </h2>
                                        <p>{profile.age ? <>{profile.age} years old</> : <>age not set</>}</p>
                                    </header>
                                    <div className="profile-settings">
                                        {profile.username === auth.username
                                            ?   <>
                                                    <Link to="/editprofile" className="mx-1 me-4" title="Edit profile">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                        </svg>
                                                    </Link>
                                                    <button className="mx-1" onClick={handleLogOut} title="Log out">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                                                        </svg>
                                                    </button>
                                                </>
                                            :   <>
                                                    <button type="button" disabled={reported} className="me-3 p-1" title="Report User" data-bs-toggle="modal" data-bs-target="#reportModel">
                                                        {!reported?
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
                                                            </svg>
                                                            :
                                                            <>Reported</>
                                                        }
                                                    </button>

                                                    <div className="modal fade" id="reportModel" tabIndex="-1" aria-labelledby="reportModelLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="reportModelLabel">Report User</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>Are you sure you want to report {profile.username}?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" data-bs-dismiss="modal">Cancel</button>
                                                                    <button type="button" onClick={reportUser} data-bs-dismiss="modal">Report</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button disabled={profile.friendReqs.includes(auth.username)} className="mx-1" onClick={addFriend}>
                                                        {profile.friends.includes(auth.userId) 
                                                            ? "Remove Friend"
                                                            : profile.friendReqs.includes(auth.username)
                                                                ? "Request Sent"
                                                                : "Add Friend"
                                                        }
                                                    </button>
                                                </>
                                        }
                                    </div>
                                    <div className="profile-body">
                                        <Link to={`/people/${profile.username}/friends`} className="d-block mb-2" title="Go to friends list">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className=" mb-1 me-1" style={{height: '25px'}}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                            </svg>
                                            <h5 className="d-inline">{profile.friends?.length} friends </h5>
                                        </Link>
                                        <b className="d-block">About me:</b> {profile.bio ? formatText(profile.bio) : <p>I love games</p>}
                                        <p className="mt-3"><b>Tags:</b></p> <TagList tags={profile.tags}/>
                                    </div>
                                </section>
                            </div>
                            
                            <h5 className="cover profile-posts-header mx-auto">{profile.username}'s Posts:</h5>
                            <div className="profile-posts">
                                <div>
                                    <PostList 
                                        userId={profile._id}
                                    />
                                </div>
                            </div> 

                        </>
                    ) : <p style={{marginTop: "250px"}}>User Not Found</p>
                }
            </div>
        </>
    );
}

export default User;