export default function TagList({tags}) {

    const listTags = tags.map((tag, index) =>
        <div key={index}>
            <b>{tag}</b>
        </div>
    );

    return(
        <div className="tags">
            {listTags}
        </div>
    );
};