import { useState, useRef, useEffect } from "react";
import { Link,  useNavigate/*,  useLocation */} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import BrandingBar from "../Components/BrandingBar";

const Login = () => {
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    //const location = useLocation();
    //const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [ username, setUser ] = useState('');
    const [ password, setPwd ] = useState('');
    const [ errMsg, setErrMsg ] = useState('');

    useEffect(() => {
        userRef.current.focus();
        if(auth?.userId) {
            navigate('/', { replace: true });
        }
    }, [auth?.userId, navigate]);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/auth', 
                {username, password},
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const userId = response?.data?.userId;
            setAuth({ userId, accessToken, username });
            //clearing out inputs
            setUser('');
            setPwd('');
            //navigate(from, { replace: true });
            window.location.reload(false);
        } catch (err) {
            if(!err?.response) {
                setErrMsg('No Server Response');
            } else if(err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if(err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Log In Failed');
            }
            errRef.current.focus();
        }

    }

  return (
        <section>
            <BrandingBar />
            <h1 className="text-center" style={{marginTop: "150px"}}>Log In</h1>
            <form className="accountform" onSubmit={handleSubmit}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <label htmlFor="username">Username:</label>
                <input 
                    type="text"
                    id="username"
                    placeholder="username"
                    className="p-2"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={username}
                    required
                />

                <label htmlFor="password">Password:</label>
                <input 
                    type="password"
                    id="password"
                    placeholder="password"
                    className="p-2"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                    required
                />

                <button disabled={!password || !username ? true : false} title="Log in" type="submit">Log In</button>

                <p>
                    Don't have an account? 
                    <span className="link" >
                        <Link to="/register"> Register</Link>
                    </span>
                </p>
            </form>

        </section>
    )
}
//
export default Login;