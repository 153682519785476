export default function EditTagList({tags, removeTag}) {

    const listTags = tags.map((tag, index) =>
        <div key={index}>
            <b>{tag}</b>
            <button type="button" className="text-center" onClick={() => removeTag(index)} title="Remove tag">X</button>
        </div>
    );

    return(
        <div className="tags">
            {listTags}
        </div>
    );
};