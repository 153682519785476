import axios from './api/axios';
import './CSS/App.css';
import Register from "./Pages/Register";
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Chats from './Pages/Chats';
import Search from './Pages/Search';
import Login from "./Pages/Login";
import Layout from "./Layout";
import NotFound from "./Pages/NotFound";
import RequireAuth from "./Components/RequireAuth";
import PersistantLogin from './Components/PersistantLogin';
import Navigation from './Components/Navigation';
import EditProfile from './Pages/EditProfile';
import NewPost from './Pages/NewPost';
import User from './Pages/User';
import SingularPost from './Pages/SingularPost';
import FriendsList from './Pages/FriendsList';
import MobileBlocker from './Components/MobileBlocker';

function App() {
    axios.defaults.withCredentials = true;
    return(
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route element={<MobileBlocker />}>
                    <Route element={<PersistantLogin />}>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/login" element={<Login />}></Route>

                        <Route element={<RequireAuth />}>
                            <Route element={<Navigation />}>
                                <Route path="/" element={<Home />}></Route>
                                <Route path="/newpost" element={<NewPost />}></Route>
                                <Route path="/post/:senderName/:postId" element={<SingularPost />}></Route>
                                <Route path="/search" element={<Search />}></Route>
                                <Route path="/chats" element={<Chats />}></Route>
                                <Route path="/editprofile" element={<EditProfile />}></Route>
                                <Route path="/people/:username" element={<User />}></Route>
                                <Route path="/people/:username/friends" element={<FriendsList />}></Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route path="*" element={<NotFound />}></Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;