import InfiniteScroll from "react-infinite-scroll-component";
import useAuth from "../hooks/useAuth";
import { uniqBy } from 'lodash';
import { useEffect, useState } from "react";
import useNotifs from "../hooks/useNotifs";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link } from 'react-router-dom';

const ChatBox = ({ selectedChat }) => {
    const { auth } = useAuth();
    const { wsRef, incomingMsg, chats } = useNotifs();
    const axiosPrivate = useAxiosPrivate();
    const chat = chats?.find(chat => chat.id===selectedChat);
    const chatName = chat?.name;
    const [ lastCreatedAt, setLastCreatedAt ] = useState();
    const [ hasMore, setHasMore ] = useState(true);
    const [ messages, setMessages ] = useState([]);
    const [ newMsg, setNewMsg ] = useState('');
    const [ users, setUsers ] = useState(null);

    const readMessages = async (newChat) => {
        try {
            const lastCreatedChk = newChat ? undefined : lastCreatedAt
            const response = await axiosPrivate.put('/messages', 
                {chatId: selectedChat, userId: auth.userId, lastCreatedAt: lastCreatedChk}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setMessages(prev => [...prev, ...response.data?.foundMessages]);
            setLastCreatedAt(response.data?.foundMessages[response.data?.foundMessages?.length - 1]?.createdAt);
            setUsers(response.data?.users);
            setHasMore(response.data?.hasMore);
        } catch (err) {
            setHasMore(false);
            console.error(err);
        }
    };

    // Reset chatbox state if a new chat is selected
    useEffect(() => { 
        setMessages([]);
        setLastCreatedAt(undefined);
        setHasMore(true); 
        if(selectedChat && chats?.some(chat => chat.id === selectedChat)) {
            readMessages(true);
        }
        // eslint-disable-next-line
    }, [selectedChat]);

    useEffect(() => {
        if(incomingMsg && incomingMsg !== null) {
            if('text' in incomingMsg && incomingMsg.chatId === selectedChat && incomingMsg.sender !== auth.userId) {
                setMessages(prev => ([{...incomingMsg}, ...prev]));
            } 
        }
    }, [incomingMsg, auth.userId, selectedChat]);

    const deleteMessage = async (e, index, message) => {
        e.preventDefault();
        try {
            await axiosPrivate.delete(`/messages/${message._id}`);
            // GET OUT
            const newMessageList = [...messagesNoDupes];
            newMessageList.splice(index, 1);
            setMessages(newMessageList);
        } catch (err) {
            console.error(err);
        }
    };

    const sendMsg = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.post('/messages',
                {sender:auth.userId, chatId:selectedChat, text:newMsg}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
            });
            const { sender, chatId, text, _id  } = response.data;
            setMessages(prev => ([{sender, chatId, text, _id}, ...prev]));
            wsRef.send(JSON.stringify({
                _id,
                sender,
                chatId,
                text,
            }));
        } catch (err) {
            console.error(err);
        }
        setNewMsg('');
    };

    const adjustChatName = (chatName) => {
        chatName = chatName.replace(" " + auth.username + " ", "");
        chatName = chatName.replace("and", "");
        return chatName.trim();
    };

    const messagesNoDupes = uniqBy(messages, '_id');

    return (
        <>
            {chat != null ?
                <div className="chatbox">
                    <h2 className="p-2 chatheader"><Link to={`/people/${adjustChatName(chatName)}`}> {adjustChatName(chatName)}</Link></h2>
                    <div id="messageBoxWrapper" className="messagebox-wrapper">
                        <InfiniteScroll
                            dataLength={messagesNoDupes.length}
                            next={() => readMessages(false)}
                            style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'hidden' }}
                            inverse={true}
                            hasMore={hasMore}
                            loader={<p>Loading...</p>}
                            endMessage={<p>This is the begining of the chat</p>}
                            scrollableTarget="messageBoxWrapper"
                        >
                                {messagesNoDupes.map((message, index) => (
                                    <div key={index} className={message.sender === auth.userId ? "message outgoing-message" : "message incoming-message" }>
                                        <h5>{users?.find(user => user?._id === message.sender)?.username}</h5>
                                        <p>
                                            {message.text}
                                            {message.sender === auth.userId
                                                ?
                                                <button type="button" className="ms-2 p-0" onClick={(e) => deleteMessage(e, index, message)} title="Delete message">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </button>
                                                : <></>
                                            }
                                        </p>
                                    </div>
                                ))}
                            </InfiniteScroll>
                    </div>
                    <form className="send-text b-top" onSubmit={sendMsg}>
                        <input 
                            type="text" 
                            placeholder="type message" 
                            maxLength="150"
                            value={newMsg}
                            onChange={(e) => setNewMsg(e.target.value)}
                            className="w-100 p-1 ps-3"
                        >                                    
                        </input>
                        <button type="submit" title="Send message">Send</button>
                    </form>
                </div>
            :<div className="chatbox">
                <h3 className="text-center my-auto">No Selected User</h3>
            </div>
        }
        </>
    );
};

export default ChatBox;