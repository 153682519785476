import { useState } from "react";
import { Link } from "react-router-dom";


const LFGMatch = ({ match, handleAccept, accepted, setAccepted, setSkipped, setIsSearching}) => {
    const [ selfAccepted, setSelfAccepted ] = useState(false);

    const skillText = {
        1: "Noob",
        2: "Casual",
        3: "Intermediate",
        4: "Advanced",
        5: "Pro"
    };

    const handleSkip = (e) => {
        e.preventDefault();
        setIsSearching(true);
        setSkipped(prev => ([...prev, match.username]));
    };

    const handleAdd = (e) => {
        e.preventDefault();
        if(!selfAccepted) {
            setSelfAccepted(true);
            setAccepted(1);
            handleAccept();
        }
    };

    return (
        <div className="mt-3">
            <ul className="search-results mt-4">
                <li ><Link to={`/people/${match?.username}`}>{match?.username}</Link></li>
                <li> Skill Level: {skillText[match?.skillLvl]}</li>
            </ul>
            <button type="button" className="accept-lfg" disabled={selfAccepted ? true : false} onClick={handleAdd} title="Add Friend">Add friend {accepted + "/2"}</button>
            <button type="button" className="ms-5" onClick={handleSkip} title="Skip player">Skip player</button>
        </div>
    );
};

export default LFGMatch;