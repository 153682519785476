import Comment from "./Comment";

const ListComments = ({ post, comments, setComments, axiosPrivate }) => {
    return (
        <>
            {comments
                ? comments.map((comment, index) => (
                    <Comment key={index} index={index} comments={comments} setComments={setComments} post={post} comment = {comment} axiosPrivate={axiosPrivate} />
                    ))
                : <p className="mt-3">No comments yet</p>
            }
        </>
    );
};

export default ListComments;