import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate } from 'react-router-dom';

const NewPost = () => {
    const MAX_CHARS = 250;
    const { auth } = useAuth();
    const [ post, setPost ] = useState('');
    const [ charsLeft, setCharsLeft ] = useState(MAX_CHARS);
    const axiosPrivate = useAxiosPrivate();


    const navigate = useNavigate();

    useEffect(() => {
        setCharsLeft(MAX_CHARS- post.length);
    }, [post]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.post('/posts', 
                {senderId:auth.userId, senderName:auth.username, text:post}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setPost('');
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="w-100 h-100" style={{marginTop: "68.5px"}}>
            <Link className="back-button" to='/' title="Back">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
            </Link>

            <h1 className="text-center pt-3">New Post</h1>
            <div className='cover mx-auto'>
            <form onSubmit={handleSubmit} className="post mt-2">
                <header className="post-header add-post-header">
                    {auth.username}
                </header>
                <div className="post-data">
                    0
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="comment-button mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="comment-button mx-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>
                    0
                </div>
                <section className="post-body mx-auto px-4 pt-2 pb-0">
                    <textarea 
                        id="post"
                        placeholder="I like posting..."
                        className="p-2 w-100"
                        maxLength="250"
                        onChange={(e) => setPost(e.target.value)}
                        value={post}
                        required
                    />
                </section>
                <p className='mb-0 d-inline-block mx-4 py-3 '>{charsLeft} / {MAX_CHARS}</p>
                <div className='post-data pt-2'>
                    <button type="submit" title="Post" className='d-block p-2'>
                    Post
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon ms-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                        </svg>
                    </button>
                </div>
            </form>
            </div>
        </div>
    );
};

export default NewPost;