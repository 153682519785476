import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Context/AuthProvider';
import { NotifSocketProvider } from './Context/NotifSocketProvider';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if(process.env.NODE_ENV === 'production') disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
          <AuthProvider>
            <NotifSocketProvider>
                <Routes>
                <Route path="/*" element={<App />}></Route>
                </Routes>
            </NotifSocketProvider>
          </AuthProvider>
      </BrowserRouter>
);