import { Link } from "react-router-dom";
import PostList from "../Components/PostList";
import '../CSS/Posts.css';

const Home = () => {
    return(
        <div className="w-100" style={{marginTop: "68.5px"}}>
            <Link className="create-post-link" title="New post" to="newpost">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </Link>
            <PostList />
        </div>
    );
};

export default Home;