import { useEffect, useRef } from "react";

const useWS = (url, handleNewMessage) => {
    const reconnectTimeoutRef = useRef(null);
    const wsRef = useRef();
    const retryCountRef = useRef(0);
    const messageHandlerRef = useRef(handleNewMessage);

    useEffect(() => {
        messageHandlerRef.current = handleNewMessage;
    }, [handleNewMessage]);

    useEffect(() => {
        const socketOpen = () => {
            console.log('WebSocket connected.');
            retryCountRef.current = 0;
        };
        const socketClose = () => {
            retryCountRef.current += 1;
            console.log('WebSocket closed. Attempting to reconnect...'); 
            const delay = Math.min(10000, Math.pow(2, retryCountRef.current) * 1000);
    
            reconnectTimeoutRef.current = setTimeout(() => { 
                console.log(`Reconnection attempt #${retryCountRef.current}`);
            }, delay);
        };
        const socketMessage = (e) => {
            messageHandlerRef.current(e);
        };
        const connectWs = () => {
            wsRef.current = new WebSocket(url);
            wsRef.current.addEventListener('open', socketOpen);
            wsRef.current.addEventListener('message', socketMessage);
            wsRef.current.addEventListener('close', socketClose);
        };
        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
            connectWs(); 
        }
        return () => { 
            if (reconnectTimeoutRef.current) { 
                clearTimeout(reconnectTimeoutRef.current);
            } 
            if (wsRef.current) {
                wsRef.current.removeEventListener('open', socketOpen);
                wsRef.current.removeEventListener('message', socketMessage); 
                wsRef.current.removeEventListener('close', socketClose);
                console.log('WebSocket closed.'); 
                wsRef.current.close();
            }
        };
    }, [url]);

    return wsRef.current;
};

export default useWS;