import { Link } from "react-router-dom";
import useNotifs from "../hooks/useNotifs";
import useAuth from "../hooks/useAuth";


const ListNotifications = ({ notifMenu, handleFriend }) => {
    const { notifs, newNotifs } = useNotifs();
    const { auth } = useAuth();

    return (
        <>
            {notifs ?
                <ul>
                    {notifs.length !== 0 ?
                        notifs.map((notif, index) => (
                            <li key={index} className={!notif.isRead ?  (newNotifs !== 0 ? "unread" : "") : ""}>
                                {notif.type !== "friend"
                                    ?   <Link to={
                                            notif.type === "post-like" ?
                                                    `/post/${auth.username}/${notif.refData.postId}` 
                                                :   `/post/${notif.refData.posterName}/${notif.refData.postId}`
                                        } onClick={notifMenu} title="Go to source">
                                            {notif.text}
                                        </Link>
                                    :   <>
                                            {notif.text}. <br></br>
                                            Accept? 
                                            <button type="button" className="mx-2 p-1" title="Accept friend" onClick={() => {
                                                handleFriend(notif.refData.userId, "Y", notif._id, index);
                                                notifMenu();
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                          
                                            </button> 
                                            <button type="button" className="p-1" title="Reject friend" onClick={() => {
                                                handleFriend(notif.refData.userId, "N", notif._id, index);
                                                notifMenu();
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </>
                                }
                                
                            </li>
                        ))
                    :
                    <li>No notifications</li>
                    }
                </ul>
            : <></>
            }
        </>
    );
};

export default ListNotifications;