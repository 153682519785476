import { useState, useEffect } from "react";
import '../CSS/Search.css';
import SearchBox from "../Components/SearchBox";
import LFGbox from "../Components/LFGbox";

const Search = () => {
    const [ isLfg, setIsLfg ] = useState(false);

    useEffect(() => {
    }, []);

    const handleToggle = () => {
        setIsLfg(!isLfg);
    };

    return(
        <div className="" style={{marginTop: "68.5px"}}>
            <section className="search-header pt-4">
                <button title="Select LFG" type="button" className={isLfg? "on-toggle rounded-start" : "off-toggle rounded-start"} onClick={handleToggle}>LFG</button>
                <button title="Select search" type="button" className={!isLfg? "on-toggle  rounded-end" : "off-toggle  rounded-end"} onClick={handleToggle}>Search</button>
            </section>
            {isLfg
                ?   <LFGbox />
                :   <SearchBox />
            }
        </div>
    );
}

export default Search;