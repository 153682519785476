import { Link, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useNotifs from "../hooks/useNotifs";
import ListNotifications from "./ListNotifications";

const Navigation = () => {
    const [ notifToggle, setNotifToggle ] = useState(false);

    const { auth } = useAuth();
    const { wsRef, refreshChats, notifs, setNotifs, newNotifs, setNewNotifs, chats, setChats, unreadMessages, setUnreadMessages } = useNotifs();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    const getNotifs = async () => {
        try {
            const response = await axiosPrivate.get('/notifications');
            setNotifs(response.data?.foundNotifications);
        } catch (err) {
            console.error(err);
        }
    };

    // Grabbing notifications if there aren't any yet
    useEffect(() => {
        if(!notifs) {
            getNotifs();
        } 
        // eslint-disable-next-line
    }, [axiosPrivate, notifs, setNotifs]);

    // Receiving the most recent messages from each chat, checking if its not sent by user,
    // and if it is not read, then storing the message ID for use for chat notifications.
    useEffect(() => {
        const getMessages = async () => {
            try {
                const response = await axiosPrivate.get('/messages');
                const unreadIds = [];
                response.data.map(msg => {
                    if((msg.sender !== auth.userId && !msg.isRead)) {
                        unreadIds.push(msg.chatId);
                    }
                    return null;
                });
                if(unreadIds.length !== 0) {
                    setUnreadMessages(unreadIds);
                }
            } catch (err) {
                console.error(err);
            }
        };
        getMessages();
    }, [axiosPrivate, setUnreadMessages, auth.userId]);

    // Toggling the notification menu and marking them as read on close 
    const notifMenu = async () => {
        if(notifToggle) {
            try {
                const response = await axiosPrivate.put('/notifications');
                setNotifs(response.data?.returnNotifications);
            } catch (err) {
                console.error(err);
            }
        }
        setNotifToggle(!notifToggle);
    };

    // Counting new notifications if there are any
    useEffect(() => {
        if(notifs) {
            let count = 0;
            notifs.forEach(notif => {
                if(!notif.isRead) count++;
            });
            setNewNotifs(count);
        }
    }, [notifs, setNewNotifs]);

    // For accepting friend requests
    const handleFriend = async ( userId, answer, notifId, index ) => {
        try {
            if(answer === "Y") {
                await axiosPrivate.put(`/user/${userId}`, 
                    {reqUserId:auth.userId}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                );
                await refreshChats();
                await axiosPrivate.delete(`/notifications/${notifId}`);
                await wsRef.send(JSON.stringify({
                    friendUpdate: "added",
                    receiver: userId,
                }));
                const temp = [...notifs];
                temp.splice(index, 1);
                setNotifs(temp);
                setNotifToggle(!notifToggle);
                setNewNotifs(0);
            } else if(answer === "N") {
                await axiosPrivate.delete(`/notifications/${notifId}`,
                    {username:auth.username}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                );
                const temp = [...notifs];
                temp.splice(index, 1);
                setNotifs(temp);
                setNotifToggle(!notifToggle);
                setNewNotifs(0);
            } 
        } catch (err) {
            console.error(err);
        }
    };
    
    // For initially grabbing users chatlists
    useEffect(() => {
        if (!chats) {
            refreshChats();
        }
    }, [axiosPrivate, chats, setChats, refreshChats]);

    return (
        <>
            <nav>
                <div className="nav-branding">
                    <img src="/images/logo.png" alt="logo"></img>
                    <b className="logo-color-1">guild</b><b className="logo-color-2">.LFG</b>
                </div>
                <ul className="mx-auto">
                    <li className={location.pathname === "/" ? "nav-selected" : ""}>
                        <Link to="/" className='navlink' title="Home">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                        </Link>
                    </li>
                    <li className={location.pathname === "/search" ? "nav-selected" : ""}>
                        <Link to="/search" className='navlink' title="Search">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </Link>
                    </li>
                    <li className={location.pathname === "/chats" ? "nav-selected" : ""}>
                        <Link to="/chats" className={unreadMessages.length !== 0 ? 'new-chats navlink': 'navlink'} title="Chats">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                            </svg>
                        </Link>
                    </li>
                    <li className={location.pathname === `/people/${auth.username}` ? "nav-selected" : ""}>
                        <Link to={`/people/${auth.username}`} className='navlink' title="Profile" >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </Link>
                    </li>
                </ul>
                <div className="notifs">
                    <label className={newNotifs !== 0 ? "" : "hide"}>{newNotifs}</label>
                    <button type="button" title="Notifications" className={newNotifs !== 0 ? "new-notifs notif-toggle" : "notif-toggle"} onClick={notifMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                        </svg>
                    </button>
                    {notifToggle
                        ?   <ListNotifications 
                                handleFriend={handleFriend}
                                notifMenu={notifMenu}
                            />
                        :   <></>
                    }
                </div>
            </nav>
            <Outlet />
        </>
    );
}

export default Navigation;