import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const FriendsList = () => {
    const [ friends, setFriends ] = useState();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const username = params.username?.toString() || null;

    useEffect(() => {
        const getFriends = async () => {
            try {
                const response = await axiosPrivate.get(`/friendlist/${username}`);
                setFriends(response.data);
            } catch (err) {
                console.error(err);
            }
        };
        getFriends();
    }, [axiosPrivate, username]);

    return (
        <div style={{marginTop: "75px"}}>
            <Link className="back-button" to={`/people/${username}`} title="Go back">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
            </Link>
            <h1>{username}'s Friends List</h1>
            {friends
                ? (
                    <ul className="search-results mt-4">
                        {friends.map((friend) => <li key={friend._id}><Link to={`/people/${friend.username}`} title="Go to profile">{friend.username}</Link></li>)}
                    </ul>
                ) : (<p className="mt-5">nobody here</p>)
            }
        </div>
    );
};

export default FriendsList;