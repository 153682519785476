import { useState } from "react";


const LFGForm = ({setSelection}) => {
    const [ game, setGame ] = useState('');
    const [ isCasual, setIsCasual ] = useState(false);
    const [ skillLvl, setSkillLvl ] = useState(1);
    const [ searchSkillLvl, setSearchSkillLvl ] = useState(0);
    const [ expanded, setExpanded ] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSelection({
            game,
            isCasual,
            skillLvl,
            searchSkillLvl
        });
    };

    return (
        <>
        <form className="accountform mt-3" onSubmit={handleSubmit}>
            <label>Game (please type full game name):</label>
            <input
                id="game"
                title="game input"
                value={game}
                onChange={(e) => {setGame(e.target.value)}}
                required
            >
            </input>

            <label className="w-50">Casual gaming:</label>
            <input
                className="d-inline w-50"
                title="Playing casually or not"
                type="checkbox"
                value={isCasual}
                onChange={(e) => {setIsCasual(!isCasual)}}
            ></input>

            <label> My Skill level:</label>
            <select  value={skillLvl} onChange={(e) => {setSkillLvl(Number(e.target.value))}} title="Select my skill level">
                {/* run through the options */}
                <option value={1}>Noob</option>
                <option value={2}>Casual</option>
                <option value={3}>Intermediate</option>
                <option value={4}>Advanced</option>
                <option value={5}>Pro</option>
            </select>

            <label>Searching Skill level (at least):</label>
            <select value={searchSkillLvl} onChange={(e) => {setSearchSkillLvl(Number(e.target.value))}} title="Select player skill level">
                {/* run through the options */}
                <option value={0}>No Preference</option>
                <option value={1}>Noob</option>
                <option value={2}>Casual</option>
                <option value={3}>Intermediate</option>
                <option value={4}>Advanced</option>
                <option value={5}>Pro</option>
            </select>

            <button type="submit" title="Search">Search
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ms-2 mb-1 button-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
            </button>
        </form>
        <p className="mt-3">
            Skill Level Explanation 
            <button type="button" className="expand" title={expanded? "Contract" : "Expand"} onClick={() => setExpanded(!expanded)}>
                {expanded? " ↑" : " ↓"}
            </button>
        </p>
        <section className={expanded ? "expanded mx-auto" : "hide"}>
            <p><b>Noob</b> - beginner/just starting</p>
            <p><b>Casual</b> - ok at the game but not experienced, play on the occasion</p>
            <p><b>Intermediate</b> - decent at the game, play semi regularly</p>
            <p><b>Advanced</b> - better than most, play often</p>
            <p><b>Pro</b> - some of the best players, play all the time</p>
        </section>
        </>
    );
};

export default LFGForm;