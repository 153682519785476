import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import EditTagList from "../Components/EditTagList";
import '../CSS/profile.css';

const EditProfile = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();

    const errRef = useRef();

    const [ age, setAge ] = useState('');
    const [ bio, setBio ] = useState('');
    const [ tags, setTags ] = useState([]);
    const [ newTag, setNewTag ] = useState('');
    const [ confirmMsg, setConfirmMsg ] = useState(false);
    const [ errMsg, setErrMsg ] = useState('');

    useEffect(() => {
        const username = auth.username;
        const getProfile = async () => {
            try {
                const response = await axiosPrivate.get(`/user/${username}`);
                if(response.data.tags) setTags(response.data.tags);
                if(response.data.age) setAge(response.data.age);
                if(response.data.bio) setBio(response.data.bio);
            } catch (err) {
                console.error(err);
            }
        };
        getProfile();
    }, [axiosPrivate, auth.username]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axiosPrivate.put('/user', 
                {age, bio, tags},
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            navigate(`/people/${auth.username}`, { replace: true });
        } catch (err) {
            if(!err?.response) {
                setErrMsg('No Server Response');
            } else if(err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Update Failed');
            }
            errRef.current.focus();
        }
    };

    const removeTag = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
    }

    const handleAddTag = () => {
        // Add new tag if the tag list is not full and the new tag is not blank or already added 
        if(newTag !== '' && tags.length < 25 && tags.indexOf(newTag.toUpperCase()) === -1) {
            tags.push(newTag.toUpperCase());
            setNewTag('');
        }
    }

    const handleDeleteAccount = async () => {
        try {
            await axiosPrivate.delete('/user');
            setAuth({});
        } catch (err) {
            if(!err?.response) {
                setErrMsg('No Server Response');
            }
        }
    };

    const deleteConfirmation = () => {
        setConfirmMsg(!confirmMsg);
    }

    return(
        <section className="w-100 h-100" style={{marginTop: "68.5px"}}>
            <Link className="back-button" to={`/people/${auth.username}`} title="Back">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
            </Link>

            <h1 className="text-center pt-3">Edit Profile</h1>

            <div className="cover mx-auto">
                <form className="profile-card" onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <header className="profile-header">
                        <h2>{auth.username? auth.username : <>No Server Response?</>}</h2>
                        <label htmlFor="age" className="mx-2">Age:</label>
                        <input 
                            type="number"
                            id="age"
                            min="18"
                            max="99"
                            className="p-2"
                            autoComplete="off"
                            onChange={(e) => setAge(e.target.value)}
                            value={age}
                            required
                        />
                    </header>
                    <div className="profile-body pb-4">
                        <label htmlFor="bio">About me (max 100 characters):</label>
                        <textarea 
                            id="bio"
                            className="p-2"
                            maxLength="100"
                            onChange={(e) => setBio(e.target.value)}
                            value={bio}
                            required
                        />

                        <label htmlFor="tag">Tags (25 max):</label>
                        <EditTagList tags={tags} removeTag={removeTag}/>
                        <div className="send-text">
                            <input 
                                type="text"
                                id="tag"
                                maxLength="35"
                                placeholder="enter a tag"
                                className="p-1 w-100"
                                onChange={(e) => setNewTag(e.target.value)}
                                value={newTag}
                            />
                            <button title="Add tag" type="button" className="m-0 w-25 text-nowrap" onClick={handleAddTag}>Add Tag</button>
                        </div>
                        
                        <button type="submit" className="w-100 mx-auto mt-3" title="Save changes">Save Changes</button>
                    </div>
                </form>
            </div>

            {confirmMsg 
                ?
                    <p className="errmsg w-50 text-center mx-auto mt-5" aria-live="assertive">
                        <button type="button" className="mx-2" onClick={deleteConfirmation} title="Cancel account deletion">No</button>
                        Are you sure you want to delete your account? 
                        <button type="button" className="mx-2" onClick={handleDeleteAccount} title="Confirm account deletion">Yes</button>
                    </p>
                :
                    <button type="button" className="mt-5" onClick={deleteConfirmation} title="Delete account">Delete account</button>
            }
        </section>
    );
}

export default EditProfile;