import { useEffect, useState } from "react"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Post from "./Post";
import InfiniteScroll from "react-infinite-scroll-component";

const PostList = ({ userId }) => {
    const axiosPrivate = useAxiosPrivate();
    const [ posts, setPosts] = useState([]);
    const [ lastCreatedAt, setLastCreatedAt ] = useState();
    const [ hasMore, setHasMore ] = useState(true);
    
    const getPosts = async (newUser) => {
        try {
            const lastCreatedChk = newUser ? undefined : lastCreatedAt
            const response = await axiosPrivate.get('/posts', 
                {
                    params: {lastCreatedAt:lastCreatedChk, userId},
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setPosts(prev => [...prev, ...response.data?.foundPosts]);
            setLastCreatedAt(response.data?.foundPosts[response.data?.foundPosts?.length - 1]?.createdAt);
            setHasMore(response.data?.hasMore);
        } catch (err) {
            setHasMore(false);
            console.error(err);
        }
    };

    useEffect(() => { 
        setPosts([]);
        setLastCreatedAt(undefined);
        setHasMore(true); 
        getPosts(true);
        // eslint-disable-next-line
    }, [userId]);

    return (
        <article className="mt-4">
            <InfiniteScroll
                dataLength={posts.length}
                next={() => getPosts(false)}
                hasMore={hasMore}
                loader={<h5>Loading...</h5>}
                endMessage={<p>That's all the posts</p>}
            >
            {posts 
                ? posts.map((post, index) => (
                <Post posts={posts} setPosts={setPosts} key={index} index={index} post={post} axiosPrivate={axiosPrivate} />
                ))
                : <p>Nothing to see here...</p>
            }
            </InfiniteScroll>
        </article>
    );
};

export default PostList;